import React from 'react'
import { Helmet } from "react-helmet"
import Layout from '../components/Layout'
import Header from '../components/Header'
import WaltzRollChoko from '../components/WaltzRollChoko'
import Footer from '../components/Footer'

class WaltzRollChokoPage extends React.Component{
    render(){
        return(
            <Layout>
                <Helmet>
                <meta charset="utf-8"/>
                <title>WALTZ | Waltz Roll Choko</title>
                <meta name="description" content="Prava sreća je započeti dan sa porodicom. Časa mleka, kriška slatkog namaza i dan može da počne. Sve čari tog ukusa su stale u samo jednu kanticu. "/>
                <meta name="image" content="https://waltz.rs/waltz_roll_choko_seo.jpg"/>
                <meta itemprop="name" content="WALTZ | Waltz Roll Choko"/>
                <meta itemprop="description" content="Prava sreća je započeti dan sa porodicom. Časa mleka, kriška slatkog namaza i dan može da počne. Sve čari tog ukusa su stale u samo jednu kanticu. "/>
                <meta itemprop="image" content="https://waltz.rs/waltz_roll_choko_seo.jpg"/>
                <meta name="og:title" content="WALTZ | Waltz Roll Choko"/>
                <meta name="og:description" content="Prava sreća je započeti dan sa porodicom. Časa mleka, kriška slatkog namaza i dan može da počne. Sve čari tog ukusa su stale u samo jednu kanticu. "/>
                <meta name="og:image" content="https://waltz.rs/waltz_roll_choko_seo.jpg"/>
                <meta name="og:url" content="https://waltz.rs/waltz-roll-choko"/>
                <meta name="og:site_name" content="WALTZ | Waltz Roll Choko"/>
                <meta name="og:locale" content="sr"/>
                <meta name="og:type" content="website"/>
                </Helmet>
              <Header />
              <WaltzRollChoko />
              <Footer />
            </Layout>
        );
    }
}
  export default WaltzRollChokoPage;
  